.teams_bg{
    background-color: var(--bg-color-2);
}
.dots{
  position: relative;
  margin: 5px;
}
.dot{
  width: fit-content;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 50px;
  padding-top: 0px;
  height: 40px;
  width: 40px;
  margin-right: -1px;
  margin-bottom: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.dot p{
  margin-top: -20px;
}
.dots img {
  background: var(--theme-color-1);
  box-shadow: inset -8px -8px 16px 0 rgba(0, 0, 0, .25), inset 8px 8px 16px 0 hsla(0, 0%, 100%, .2);
}

.img-text{
  text-align: center;
}

.img-text h3, .img-text p{
  margin: 5px;
}
.img-text h3{
  font-size: 1.8rem;
  font-family: "Neue Machina",sans-serif;
  font-weight: 700;
}
.img-text p{
  margin-top: -10px;
  font-size: 1.3rem;
}
.img_text_hover{
  display: none;
}
.img_text_show{
  position: absolute;
  top: 0;
  padding: 20px;
  text-align: justify;
  display: block;
}
.opacity{
  opacity: 0.4;
}