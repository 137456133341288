*, ::after, ::before {
  box-sizing: border-box;
}

:root{
  --theme-color-1: #0079BE;
  --theme-color-2: #00EDF6;

  --base-color-1: #000;
  --base-color-2: #fff;
  --base-color-3: #dcd1ef;
  --base-color-4: #B0BCDF;

  /* button color  */
  --button-color-1: var(--theme-color-1);

  /* background color  */
  --bg-color-1:var(--base-color-2);
  --bg-color-2:var(--base-color-3);

  /* footer background  */
  --footer-bg-1:var(--theme-color-1);

  /* header background  */
  --header-bg-1:var(--base-color-4);

  /* h1 color  */
  --h1-color-1:var(--base-color-2);

}
