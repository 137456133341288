.who_we_are_bg{
    background-color: var(--bg-color-2);
}
.about_title{
    font-family: Onyx;
    font-weight: 400;
    font-size: 9vw;
    text-align: center;
    line-height: 65px;
    text-transform: uppercase;    
    margin-top: 150px;
    margin-bottom: 100px;
    width: 100%;
}

.step_item1,.step_cta1{
    padding-left: 45px;
    padding-bottom: 32px;
    position: relative;
    visibility: visible;
    -webkit-transform: translateY(0) scale(1);
    opacity: 1;
    transform: translateY(0) scale(1);
    opacity: 1;
    -webkit-transition: -webkit-transform 0.6s ease 0.3s, opacity 0.6s ease 0.3s;
    transition: transform 0.6s ease 0.3s, opacity 0.6s ease 0.3s;
}
.step_title1{
    margin-bottom: 24px;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.33;
    margin: 0 0 20px;
}
.step_text1{
    color: #373737;
    max-width: 720px;
}
.step_item1:before,.step_cta1:before {
    border-left: 1px solid #0045E6;
    content: '';
    height: 100%;
    left: 6px;
    position: absolute;
}
.step_item1:before{
    top: 20px;
}
.step_cta1:before{
    top: -35px;
}
.step_item1:after,.step_cta1:after {
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
    content: '';
    left: 0;
    position: absolute;
    height: 13px;
    width: 13px;    
}
.step_item1:after{
    background-color: #0045E6;
    top: 13px;
}
.step_cta1:after {
    background-color: #FF4925;
    top: 9px;
}
.link1{
    text-decoration: none;
}
.link_text1{
    color: #0045E6;
    font-weight: 600;
}

/* left div scroll and right div fixed */

.step_item1,.step_cta1{
    position: relative;
}  
.steps_imgWrap1{ 
    padding: 10px;   
    position: sticky;
    top: 60px;
}