.what_you_get_bg{
    background-color: var(--bg-color-1);
}
.step_item,.step_cta{
    padding-left: 45px;
    padding-bottom: 32px;
    position: relative;
    visibility: visible;
    -webkit-transform: translateY(0) scale(1);
    opacity: 1;
    transform: translateY(0) scale(1);
    opacity: 1;
    -webkit-transition: -webkit-transform 0.6s ease 0.3s, opacity 0.6s ease 0.3s;
    transition: transform 0.6s ease 0.3s, opacity 0.6s ease 0.3s;
}
.step_title {
    margin-bottom: 24px;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.33;
    margin: 0 0 20px;
}
.step_text {
    color: #373737;
    max-width: 720px;
}
.step_item:before,.step_cta:before {
    border-left: 1px solid #C9CEDE;
    content: '';
    height: 100%;
    left: 6px;
    position: absolute;
}
.step_item:before{
    top: 20px;
}
.step_cta:before{
    top: -35px;
}
.step_item:after,.step_cta:after {
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
    content: '';
    left: 0;
    position: absolute;
    height: 13px;
    width: 13px;
}
.step_item:after{
    background-color: #0045E6;
    top: 13px;
}
.step_cta:after {
    background-color: #FF4925;
    top: 9px;
}
.link{
    text-decoration: none;
}
.link_text{
    color: #0045E6;
    font-weight: 600;
}

/* left div scroll and right div fixed */

.step_item,.step_cta {
    position: relative;
}  
.steps_imgWrap { 
    padding: 10px;   
    position: sticky;
    top: 60px;
}