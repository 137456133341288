.bubble_bg{
    background-color: var(--bg-color-2);
}
#bubbles-container{
    width: 100%;
    max-height: 510px;
    height: 100%;
    overflow: hidden;
    background-image: url("../../../images/mission-bubbles-desktop.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}
/* .bubbles img{
    width: 50%;  
    margin-left: auto;  
    margin-right: auto; 
} */
/* .bubbles .bubbles-img-overlay {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    margin-top: 120px;
} */

@media screen and (max-width: 480px) {
    #bubbles-container{
        max-height: 400px;
        background-image: url("../../../images/mission-bubbles-desktop1.png");
        background-size: 170%;
    }
}
