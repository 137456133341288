.top{
    display: none;
}
.transparent .top{
    display: block;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 10px 13px;
    border-radius: 20px;
}
.transparent .top:hover {
    background-color: #555;
}
  