.main .signup{
    margin-top: 90px; 
}
.signup .form-title {
    margin-bottom: 33px; 
    line-height: 1.66;
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #222;
    font-family: Poppins;
    font-size: 36px;
}
@media screen and (max-width: 768px){    
    .signup .form-title{
      text-align: center; 
    }
}