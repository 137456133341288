.hero-section {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 152px 15px 70px 15px;
    background-size: cover;
    min-height: 100vh;
    background-image: url("../../../images/cynob_empower_your_digital_transformation1.jpg");
}
.hero-section h1 {
    color: var(--h1-color-1);
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    font-size: 8vw;
    line-height: 1.02;
    font-weight: normal;
    word-wrap: break-word;
}
@media screen and (max-width: 480px) {
    .hero-section{
        min-height: 50vh;
        padding: 15px 15px 70px;
    }
}
