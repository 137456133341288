.herosectionbgcolor{
    background-color: var(--theme-color-1);
}
.hero-section1 {
    background-size: cover;
    min-height: 100vh;
    background-image: url("../../../images/cynob_empower_your_digital_transformation1.jpg");
    padding: 25px;
}
.hero-section1 h1 {
    color: var(--h1-color-1);
    position: relative;
    z-index: 2;
    line-height: 1.02;
    font-weight: normal;
    word-wrap: break-word;
    font-size: 40px;
    font-weight: bold;
    margin-top: 150px;
}
.hero-section1 p{
    color: var(--base-color-2);
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 54px;
    max-width: 480px;
    opacity: 1;
}
.form-head{
    color: var(--base-color-2);
    position: relative;
    z-index: 2;
    font-size: 28px;
    font-weight: bold;
    margin-top: 100px;
    padding: 10px;    
}
.medium, .labelChoice{
    background: 0 0;
    border: none;
    color: var(--base-color-2);
    line-height: 1.5;
    outline: 0;
    margin: 15px 20px;
    width: 80%;
}
.medium{    
    border-bottom: 1px solid var(--base-color-2);
}
.medium::placeholder {
    color: var(--base-color-2);
}
.textarea{
    height: 34px;
    overflow:hidden; 
    transition: height 0.2s ease-out;
}
.form_info{
    color: var(--base-color-2);
    font-size: .75rem;
    line-height: 1.37;
    margin: 20px 35px;
}
.form_info a{
    text-decoration: none;
    color: var(--base-color-2);
    font-weight: bold;
}
.contact_form_button{
    color: var(--theme-color-1);
    background-color: var(--theme-color-2);  
    border-radius: 50px;
    border: none;
    min-width: 220px;
    font-weight: 600;    
    padding: 15px;
    transition-duration: .3s;
    margin-bottom: 15px;
}
.contact_form_button:hover{
    color: var(--base-color-1);
    background-color: var(--base-color-2);
}
.inputchoice{
    margin-left: 5px;
}