  /* poppins-300 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url("../../../fonts/poppins/poppins-v5-latin-300.eot");
    /* IE9 Compat Modes */
    src: local("Poppins Light"), local("Poppins-Light"), url("../../../fonts/poppins/poppins-v5-latin-300.eot?#iefix") format("embedded-opentype"), url("../../../fonts/poppins/poppins-v5-latin-300.woff2") format("woff2"), url("../../../fonts/poppins/poppins-v5-latin-300.woff") format("woff"), url("../../../fonts/poppins/poppins-v5-latin-300.ttf") format("truetype"), url("../../../fonts/poppins/poppins-v5-latin-300.svg#Poppins") format("svg");
    /* Legacy iOS */ 
  }
.main {
    background: #dcd1ef;
    padding: 1px 0px;
    height: 100vh;   
    font-size: 13px;
    line-height: 1.8;
    color: #222;
    font-weight: 400;
    font-family: Poppins; 
}
.main .signin {
    margin-top: 90px; 
}
.main .container {
    width: 900px!important;
    background: #fff;
    margin: 0 auto;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px; 
}
@media screen and (max-width: 1200px) {
  .main .container {
      width: calc( 100% - 30px);
      max-width: 100%; 
    } 
}
@media screen and (min-width: 1024px) {
  .main .container {
      max-width: 1200px; 
    } 
}

.signup-content, .signin-content{
  display: flex;
  display: -webkit-flex; 
}
.signin-content {
  padding-block: 40px;
}
@media screen and (max-width: 768px) {
  .signup-content, .signin-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center; 
  }
}
.signup-form, .signup-image, .signin-form, .signin-image {
  width: 50%;
  overflow: hidden; 
}
  .signin-image {
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 10px; 
  }
  @media screen and (max-width: 768px) {
    .signin-image {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 50px;
      order: 2;
      -moz-order: 2;
      -webkit-order: 2;
      -o-order: 2;
      -ms-order: 2; 
    }
  
    .signup-form, .signup-image, .signin-form, .signin-image {
      width: auto; 
    }
  }

  .signin-image figure {
    margin: 0; 
  }
  .signin-image figure {
    margin-bottom: 50px;
    text-align: center; 
  }
  .signin-image img {
    max-width: 100%;
    height: auto; 
  }
  .main .signin-form {
    margin-inline: 70px;
  }
  @media screen and (max-width: 768px) {
    .main .signin-form {
      order: 1;
      -moz-order: 1;
      -webkit-order: 1;
      -o-order: 1;
      -ms-order: 1;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0 30px; }
  }
  .signin .form-title {
    margin-bottom: 33px; 
    line-height: 1.66;
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #222;
    font-family: Poppins;
    font-size: 36px;
  }
  @media screen and (max-width: 768px) {
    
    .signin .form-title{
      text-align: center; 
    } 
  }
  .signin-form .register-form {
    width: 100%; 
  }
  .register-form .form-group {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden; 
  }
  .register-form .form-group:last-child {
    margin-bottom: 0px; 
  }
    
  .register-form label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222; 
  }

  .register-form input {
    outline: none;
    appearance: unset !important;
    -moz-appearance: unset !important;
    -webkit-appearance: unset !important;
    -o-appearance: unset !important;
    -ms-appearance: unset !important; 
  }
  
  .register-form input::-webkit-outer-spin-button, .register-form input::-webkit-inner-spin-button {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    margin: 0; 
  }
  
  .register-form input:focus{
    outline: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important; 
  }
  
  .register-form input {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    font-family: Poppins;
    box-sizing: border-box; 
  }
  .register-form  input::-webkit-input-placeholder {
      color: #999; 
  }
  .register-form  input::-moz-placeholder {
      color: #999; 
  }
  .register-form  input:-ms-input-placeholder {
      color: #999; 
  }
  .register-form  input:-moz-placeholder {
      color: #999; 
  }
  .register-form  input:focus {
      border-bottom: 1px solid #222; 
  }
  .register-form    input:focus::-webkit-input-placeholder {
      color: #222; 
  }
  .register-form    input:focus::-moz-placeholder {
      color: #222; 
  }
  .register-form    input:focus:-ms-input-placeholder {
      color: #222; 
  }
  .register-form    input:focus:-moz-placeholder {
      color: #222; 
  }
  .register-form .material-icons-name {
    font-size: 18px; 
  }


  
  
  
  
  
  
  
  
  
  /* Registration Page CSS -  */


  .signup {
    margin-bottom: 150px;   
  }  
  .signup-content {
    padding: 40px 0; 
  } 
  .signup-image {
    margin: 0 40px; 
    margin-top: 45px; 
  }
  .form-submit {
    display: inline-block;
    background: #6dabe4;
    color: #fff;
    border-bottom: none;
    width: auto;
    padding: 10px 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    margin-top: 25px;
    cursor: pointer; 
  }
  .form-submit:hover {
    background: #4292dc; 
  }
  .signup-image-link {
    font-size: 14px;
    color: #222;
    display: block;
    text-align: center; 
  }  
  .signup-form {
    margin-inline: 40px;
  }
  

  

  @media screen and (max-width: 768px) {
  
    .signup-form {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      /* box-sizing: border-box; */
      padding: 0 30px; 
    }
    .form-button {
      text-align: center; 
    }
  
  }
  
  
  
  /*# sourceMappingURL=style.css.map */
  
  